<template>
  <div>
    <ejs-dialog
      ref="depositUseReceiptPopup"
      header="선수금 내역서"
      :allowDragging="true"
      :showCloseIcon="true"
      width="380"
      height="600"
      :isModal="true"
      @close="onDepositUseReceiptPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div
                      class="body-box"
                      style="padding: 18px; color: #000; width: 280px"
                    >
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 0 0 14px 0;
                        "
                      >
                        <div
                          style="
                            font-size: 16px;
                            font-weight: bold;
                            text-align: center;
                          "
                        >
                          선수금 내역서
                        </div>
                        <div style="padding-top: 9px">
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            발행일자
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ depositUseReceipt.printDt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 115px);
                              padding-right: 6px;
                            "
                          >
                            입금자명
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 115px;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ depositUseReceipt.memberName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 115px);
                              padding-right: 6px;
                            "
                          >
                            연락처
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 115px;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{
                              getFormattedTelNumber(
                                depositUseReceipt.contactTel
                              )
                            }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 115px);
                              padding-right: 6px;
                            "
                          >
                            선수금액
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 75px;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{
                              depositUseReceipt.depositAmt | numberWithCommas
                            }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 115px);
                              padding-right: 6px;
                            "
                          >
                            누적사용
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 75px;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ depositUseReceipt.sumUseAmt | numberWithCommas }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 115px);
                              padding-right: 6px;
                            "
                          >
                            사용금액
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 75px;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ depositUseReceipt.useAmt | numberWithCommas }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 115px);
                              padding-right: 6px;
                            "
                          >
                            잔여금액
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 75px;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{
                              depositUseReceipt.balanceAmt | numberWithCommas
                            }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 60px;
                              text-align: center;
                            "
                          >
                            사용일자
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 80px;
                              padding-left: 6px;
                              text-align: center;
                            "
                          >
                            출발시간
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 60px;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            사용금액
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 80px;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            사용구분
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 0px;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div
                          v-for="item in depositUseReceipt.depositUseList"
                          :key="item.useId"
                        >
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 60px;
                            "
                          >
                            {{ item.useDate }}
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 80px;
                              padding-left: 6px;
                              text-align: center;
                            "
                            :style="{
                              height: item.startTime ? '' : '18px'
                            }"
                          >
                            {{ item.startTime }}
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 60px;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ item.useAmt | numberWithCommas }}
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 80px;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{
                              commonCodesGetComName(
                                "DEPOSIT_USE_DIV",
                                item.depositUseDiv
                              )
                            }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 115px);
                              padding-right: 6px;
                            "
                          >
                            합계
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 115px;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{
                              depositUseReceipt.totalUseAmt | numberWithCommas
                            }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 0px;
                          padding: 20px 0 1px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 50%;
                              padding-right: 6px;
                            "
                          >
                            가맹점명
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 50%;
                              padding-left: 6px;
                              text-align: left;
                            "
                          >
                            {{ depositUseReceipt.bizName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 0px;
                          padding: 1px 0 1px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 50%;
                              padding-right: 6px;
                            "
                          >
                            사업자번호
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 50%;
                              padding-left: 6px;
                              text-align: left;
                            "
                          >
                            {{ bizMstInfo.bizNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 0px;
                          padding: 1px 0 1px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 50%;
                              padding-right: 6px;
                            "
                          >
                            대표자명
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 50%;
                              padding-left: 6px;
                              text-align: left;
                            "
                          >
                            {{ bizMstInfo.rprsntvName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 0px;
                          padding: 1px 0 1px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 100%;
                              padding-right: 6px;
                            "
                          >
                            {{ depositUseReceipt.addr }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 0px;
                          padding: 1px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 50%;
                              padding-right: 6px;
                            "
                          >
                            TEL
                            {{ getFormattedTelNumber(bizMstInfo.telNo, ")") }}
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 50%;
                              padding-left: 6px;
                              text-align: left;
                            "
                          >
                            FAX
                            {{ getFormattedTelNumber(bizMstInfo.faxNo, ")") }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="print keyColor">
              <erp-button button-div="PRINT" @click.native="print"> 출력 </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closeDepositUseReceiptPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import {
  commonCodesGetComName,
  commonCodesGetBizMstInfo,
} from "@/utils/commonCodes";
import BillPrintUtil from "@/utils/billPrintUtil";
import { getFormattedTelNumber } from "@/utils/string";
import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "DepositUseReceiptPopup",
  mixins: [confirmDialogMixin],
  components: {
    ErpButton,
  },
  filters: {
    numberWithCommas,
  },
  created() {
    this.bizMstInfo = commonCodesGetBizMstInfo();

    this.bizMstInfo.bizNo =
        this.bizMstInfo.bizNo.substr(0, 3) +
        "-" +
        this.bizMstInfo.bizNo.substr(3, 2) +
        "-" +
        this.bizMstInfo.bizNo.substring(5);
  },
  data() {
    return {
      depositUseReceipt: {
        useId: null,
        depositId: null,
        cardId: null,
        printDt: null,
        cardNo: null,
        depositAmt: null,
        sumUseAmt: null,
        useAmt: null,
        totalUseAmt: null,
        balanceAmt: null,
        useDate: null,
        startCourse: null,
        startTime: null,
        depositUseDiv: null,
        depositUseList: [],
        memberName: null,
        contactTel: null,
      },
      bizMstInfo: {},
    };
  },
  methods: {
    commonCodesGetComName,
    getFormattedTelNumber,
    async showDepositUseReceiptPopup(popupData) {
      const useId = popupData ? popupData : null;

      await this.fetchDepositUseReceipt(useId);
    },
    onDepositUseReceiptPopupClosed() {
      this.$emit("popupClosed");
    },
    closeDepositUseReceiptPopup() {
      this.$refs.depositUseReceiptPopup.hide();
    },
    async fetchDepositUseReceipt(useId) {
      this.depositUseReceipt = await GolfErpAPI.fetchDepositUseReceipt(useId);
    },
    async print() {

      let depositUseList = JSON.parse(
        JSON.stringify(this.depositUseReceipt.depositUseList)
      );

      depositUseList.forEach((data) => {
        data.depositUseDiv =
          data.depositUseDiv === undefined || data.depositUseDiv === null
            ? ""
            : commonCodesGetComName("DEPOSIT_USE_DIV", data.depositUseDiv);
      });

      const printSetData = {
        printDt: this.depositUseReceipt.printDt,
        cardNo:
          this.depositUseReceipt.cardNo === undefined ||
          this.depositUseReceipt.cardNo === null
            ? ""
            : this.depositUseReceipt.cardNo,
        depositAmt: this.depositUseReceipt.depositAmt,
        sumUseAmt: this.depositUseReceipt.sumUseAmt,
        useAmt: this.depositUseReceipt.useAmt,
        balanceAmt: this.depositUseReceipt.balanceAmt,
        totalUseAmt: this.depositUseReceipt.totalUseAmt,
        useDate: this.depositUseReceipt.useDate,
        ccName: this.bizMstInfo.ccName,
        bizNo: this.bizMstInfo.bizNo,
        rprsntvName: this.bizMstInfo.rprsntvName,
        addr: this.depositUseReceipt?.addr,
        telNo: getFormattedTelNumber(this.bizMstInfo.telNo, ")"),
        faxNo: getFormattedTelNumber(this.bizMstInfo.faxNo, ")"),
        depositUseList: depositUseList,
        memberName: this.depositUseReceipt.memberName,
        contactTel: getFormattedTelNumber(this.depositUseReceipt.contactTel),
        merchantName: this.depositUseReceipt?.bizName,
      };
      const config = {
        "useBillPrinter": true,
        "useKitchenPrinter": false,
      };
      await BillPrintUtil.getTsConfReceiptInfo.call(this, "DEPOSIT_RECEIPT", true, printSetData, config);


    },
  },
};
</script>
